import React from 'react';
import { Link } from 'react-router-dom';

const Card = (props) => {
	const secondToHms = (d) => {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor((d % 3600) / 60);
		var s = Math.floor((d % 3600) % 60);

		var hDisplay = h > 0 ? h + ' jam ' : '';
		var mDisplay = m > 0 ? m + ' menit ' : '';
		var sDisplay = s > 0 ? s + ' detik' : '';

		return hDisplay + mDisplay + sDisplay;
	};

	return (
		<div className='bg-white shadow-md rounded border overflow-hidden'>
			<div className='flex flex-col'>
				<div className='py-2 px-4 bg-[#DE9E00] text-white text-center font-medium'>
					{props.result.namapoli}
				</div>
				<div className='py-2 px-4'>
					<div className='flex flex-row'>
						<h4 className='basis-1/2 text-md tracking-wide italic'>Tunggu Adm</h4>
						<div className='text-right basis-1/2'>
							<h4 className='text-md tracking-wide'>{secondToHms(props.result.avg_waktu_task1)}</h4>
						</div>
					</div>
					<div className='flex flex-row'>
						<h4 className='basis-1/2 text-md tracking-wide italic'>Layan Adm</h4>
						<div className='text-right basis-1/2'>
							<h4 className='text-md tracking-wide'>{secondToHms(props.result.avg_waktu_task2)}</h4>
						</div>
					</div>
					<div className='flex flex-row'>
						<h4 className='basis-1/2 text-md tracking-wide italic'>Tunggu Poli</h4>
						<div className='text-right basis-1/2'>
							<h4 className='text-md tracking-wide'>{secondToHms(props.result.avg_waktu_task3)}</h4>
						</div>
					</div>
					<div className='flex flex-row'>
						<h4 className='basis-1/2 text-md tracking-wide italic'>Jumlah Pasien</h4>
						<div className='text-right basis-1/2'>
							<h4 className='text-md tracking-wide'>{props.result.jumlah_antrean}</h4>
						</div>
					</div>
				</div>
				<div className='py-2 px-4 text-center bg-hi border-t'>
					<Link
						to={`/pasien`}
						className='text-gray-900 tracking-wide hover:text-[#DE9E00]'
						state={{ from: props }}
					>
						Lihat Detail Poli
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Card;
