import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../../utils/Contants';
import * as XLSX from 'xlsx/xlsx.mjs';
import { SiMicrosoftexcel } from 'react-icons/si';
import { GoCalendar } from 'react-icons/go';
import { IoArrowBackOutline } from 'react-icons/io5';

const Poli = () => {
	const location = useLocation();
	let { tanggal } = location.state.from.result;
	let { namapoli } = location.state.from.result;

	const [hasil, setHasil] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		axios
			.post(`${API_URL}detailPoli`, {
				tanggal,
				namapoli,
			})
			.then((resp) => {
				console.log(resp);
				if (Object.keys(resp.data).length === 0) {
					setHasil(null);
				} else {
					setHasil([...resp.data]);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error.message);
				setLoading(false);
			});
	}, [tanggal, namapoli]);

	const tableNoData = (
		<>
			<div className='flex justify-start items-center mb-3'>
				<h1 className='text-lg font-medium'>
					Poli : {namapoli} <span className='mx-3'>|</span> <GoCalendar /> :{' '}
					<span className='italic'>{tanggal}</span>
				</h1>
			</div>
			<div className='table-wrp relative overflow-x-auto shadow-md overflow-hidden '>
				<table className='bg-white border-collapse border-slate-400 w-full text-left text-sm text-gray-500 max-h-96'>
					<thead
						className='w-full uppercase text-white bg-[#2B7A0B] sticky top-0'
						style={{ borderStyle: 'hidden' }}
					>
						<tr>
							<th className='px-6 py-3 text-center border text-sm'>#</th>
							<th className='px-6 py-3 text-center border text-sm'>kd-booking</th>
							<th className='px-6 py-3 text-center border text-sm'>ID</th>
							<th className='px-6 py-3 text-center border text-sm'>No. RM</th>
							<th className='px-6 py-3 text-center border text-sm'>Nama</th>
							<th className='px-6 py-3 text-center border text-sm'>Dokter</th>
							<th className='px-6 py-3 text-center border text-sm'>Jenis</th>
							<th className='px-6 py-3 text-center border text-sm'>Check-in</th>
							<th className='px-6 py-3 text-center border text-sm'>Mulai-Poli</th>
							<th className='px-6 py-3 text-center border text-sm'>Akhir-Poli</th>
							<th className='px-6 py-3 text-center border text-sm'>Mulai-Farmasi</th>
							<th className='px-6 py-3 text-center border text-sm'>Akhir-Farmasi</th>
						</tr>
					</thead>
					<tbody>
						<tr className='bg-white dark:border-gray-700 dark:bg-gray-800'>
							<td
								colSpan={11}
								className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 text-center'
							>
								Tidak Ada Data
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);

	const spiner = () => {
		const list = [];
		for (let j = 0; j < 20; j++) {
			list.push(
				<tr className='bg-white transition duration-300 ease-in-out' key={j}>
					<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b'>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</td>
					<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b'>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</td>
					<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b'>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</td>
					<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b'>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</td>
					<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b'>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</td>
					<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b'>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</td>
					<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b text-center'>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</td>
					<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b text-center'>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</td>
					<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b text-center'>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</td>
					<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b text-center'>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</td>
					<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b text-center'>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</td>
					<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b text-center'>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</td>
				</tr>
			);
		}
		return (
			<>
				<div className='flex justify-between items-center my-3'>
					<Link
						to={`/`}
						className='py-1.5 px-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-400 hover:border-transparent hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-200'
					>
						<div className='flex justify-center items-center gap-2'>
							<IoArrowBackOutline />
							Kembali
						</div>
					</Link>
					<h1 className='text-lg font-medium flex justify-start items-center'>
						Poli <span className='capitalize ml-1'>{namapoli}</span> <span className='mx-3'>|</span>{' '}
						<GoCalendar className='text-green-600 mr-1' />
						<span className='ml-1'>{tanggal}</span>
					</h1>
					<button
						className='focus:outline-none text-gray-800 hover:text-green-700 text-md font-medium rounded-lg flex justify-center items-center'
						onClick={handleExport}
					>
						<div className='h-2 bg-gray-200 rounded col-span-2'></div>
					</button>
				</div>
				<div className='table-wrp relative overflow-x-auto overflow-hidden animate-pulse'>
					<table className='border-collapse border-slate-400 w-full text-left text-sm text-gray-500 max-h-96'>
						<thead className='w-full uppercase text-gray-900 bg-gray-100 sticky top-0'>
							<tr>
								<th className='px-6 py-3 text-center border-b text-sm'>#</th>
								<th className='px-6 py-3 text-center border-b text-sm'>kd-booking</th>
								<th className='px-6 py-3 text-center border-b text-sm'>ID</th>
								<th className='px-6 py-3 text-center border-b text-sm'>No. RM</th>
								<th className='px-6 py-3 text-center border-b text-sm'>Nama</th>
								<th className='px-6 py-3 text-center border-b text-sm'>Dokter</th>
								<th className='px-6 py-3 text-center border-b text-sm'>Jenis</th>
								<th className='px-6 py-3 text-center border-b text-sm'>Checkin</th>
								<th className='px-6 py-3 text-center border-b text-sm'>M-Poli</th>
								<th className='px-6 py-3 text-center border-b text-sm'>A-Poli</th>
								<th className='px-6 py-3 text-center border-b text-sm'>M-Farmasi</th>
								<th className='px-6 py-3 text-center border-b text-sm'>A-Farmasi</th>
							</tr>
						</thead>
						<tbody>{list}</tbody>
					</table>
				</div>
			</>
		);
	};

	const handleExport = () => {
		let wb = XLSX.utils.book_new(),
			ws = XLSX.utils.json_to_sheet(hasil);

		XLSX.utils.book_append_sheet(wb, ws, 'MYSheet1');

		XLSX.writeFile(wb, `Poli_${namapoli}_${tanggal}.xlsx`);
	};

	return (
		<>
			{loading ? (
				spiner()
			) : hasil === null ? (
				tableNoData
			) : (
				<>
					<div className='flex justify-between items-center my-3'>
						<Link
							to={`/`}
							className='py-1.5 px-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-400 hover:border-transparent hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-200'
						>
							<div className='flex justify-center items-center gap-2'>
								<IoArrowBackOutline />
								Kembali
							</div>
						</Link>
						<h1 className='text-lg font-medium flex justify-start items-center'>
							Poli <span className='capitalize ml-1'>{namapoli}</span>{' '}
							<span className='mx-3'>|</span> <GoCalendar className='text-green-600 mr-1' />
							<span className='ml-1'>{tanggal}</span>
						</h1>
						<button
							className='focus:outline-none text-gray-800 hover:text-green-700 text-md font-medium rounded-lg flex justify-center items-center'
							onClick={handleExport}
						>
							<SiMicrosoftexcel className='mr-2 text-green-600 hover:text-green-700 text-lg' />{' '}
							Download to Excel
						</button>
					</div>
					<div className='table-wrp relative overflow-x-auto overflow-hidden'>
						<table className='border-collapse border-slate-400 w-full text-left text-sm text-gray-500 max-h-96'>
							<thead className='w-full uppercase text-gray-900 bg-gray-100 sticky top-0'>
								<tr>
									<th className='px-6 py-3 text-center border-b text-sm'>#</th>
									<th className='px-6 py-3 text-center border-b text-sm'>kd-booking</th>
									<th className='px-6 py-3 text-center border-b text-sm'>ID</th>
									<th className='px-6 py-3 text-center border-b text-sm'>No. RM</th>
									<th className='px-6 py-3 text-center border-b text-sm'>Nama</th>
									<th className='px-6 py-3 text-center border-b text-sm'>Dokter</th>
									<th className='px-6 py-3 text-center border-b text-sm'>Jenis</th>
									<th className='px-6 py-3 text-center border-b text-sm'>Checkin</th>
									<th className='px-6 py-3 text-center border-b text-sm'>M-Poli</th>
									<th className='px-6 py-3 text-center border-b text-sm'>A-Poli</th>
									<th className='px-6 py-3 text-center border-b text-sm'>M-Farmasi</th>
									<th className='px-6 py-3 text-center border-b text-sm'>A-Farmasi</th>
								</tr>
							</thead>
							<tbody>
								{hasil.map((res, idx) => {
									return (
										<tr className='bg-white transition duration-300 ease-in-out' key={idx}>
											<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b'>
												{idx + 1}
											</td>
											<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b'>
												<Link
													to={`/task-id/${res.kdbooking}`}
													className='py-1.5 px-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-400 hover:border-transparent hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-200'
												>
													{res.kdbooking}
												</Link>
											</td>
											<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b'>
												{res.idpx}
											</td>
											<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b'>
												{res.norm}
											</td>
											<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b'>
												{res.nama}
											</td>
											<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b'>
												{res.dokter}
											</td>
											<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b text-center'>
												{res.pxrs}
											</td>
											<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b text-left'>
												{res.checkin}
												<br />
												{res.notif}
											</td>
											<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b text-center'>
												{res.mulaipoli}
											</td>
											<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b text-center'>
												{res.akhirpoli}
											</td>
											<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b text-center'>
												{res.mulaifarmasi}
											</td>
											<td className='px-6 py-4 whitespace-nowrap font-normal text-gray-900 border-b text-center'>
												{res.akhirfarmasi}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</>
			)}
		</>
	);
};

export default Poli;
