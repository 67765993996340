import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../../../utils/Contants';
import { BiArrowBack } from 'react-icons/bi';
import Spiner from '../base/Spiner';

const TaskID2 = () => {
	const navigate = useNavigate();

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	let { kdbooking } = useParams();

	useEffect(() => {
		setLoading(true);
		axios
			.post(`${API_URL}taskid`, {
				kdbooking,
			})
			.then((res) => {
				if (res.data.code !== 204) {
					setData([...res.data]);
				} else {
					console.log('No Data');
				}
				setLoading(false);
			});
	}, [kdbooking]);

	const spiner = (
		<tr className='bg-white border-b'>
			<td colSpan={4} className='text-center border border-slate-300 py-3'>
				<div className='flex items-center justify-center'>
					<Spiner /> Loading...
				</div>
			</td>
		</tr>
	);

	const trNull = (
		<tr className='bg-white border-b'>
			<td className='border border-slate-300 py-3' colSpan={4}>
				<span className='text-center block font-medium text-gray-600 whitespace-nowrap'>
					No Data
				</span>
			</td>
		</tr>
	);

	const dataFinal = (data) => {
		if (data === null) {
			return trNull;
		} else {
			return data.map((res, index) => (
				<tr className='bg-white border-b' key={index}>
					<td className='border border-slate-300 px-6 py-4 text-center'>{res.taskid}</td>
					<td className='border border-slate-300 px-6 py-4 text-center'>{res.taskname}</td>
					<td className='border border-slate-300 px-6 py-4 text-center'>{res.waktu}</td>
					<td className='border border-slate-300 px-6 py-4 text-center'>{res.wakturs}</td>
				</tr>
			));
		}
	};

	return (
		<React.Fragment>
			<div className='w-full'>
				<button
					className='mb-3 py-1.5 px-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-400 hover:border-transparent hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-200'
					onClick={() => navigate(-1)}
				>
					<div className='flex justify-center items-center'>
						<BiArrowBack className='mr-2' />
						Kembali
					</div>
				</button>
				<div className='table-wrp relative overflow-x-auto overflow-hidden'>
					<table className='border-collapse border border-slate-400 w-full'>
						<thead className='text-gray-900 bg-gray-100'>
							<tr>
								<th className='border border-slate-300 px-5 py-2.5 font-medium text-md whitespace-nowrap'>
									TaskID
								</th>
								<th className='border border-slate-300 px-5 py-2.5 font-medium text-md whitespace-nowrap'>
									Keterangan
								</th>
								<th className='border border-slate-300 px-5 py-2.5 font-medium text-md whitespace-nowrap'>
									Waktu
								</th>
								<th className='border border-slate-300 px-5 py-2.5 font-medium text-md whitespace-nowrap'>
									WaktuRS
								</th>
							</tr>
						</thead>
						<tbody>{loading ? spiner : dataFinal(data)}</tbody>
					</table>
				</div>
			</div>
		</React.Fragment>
	);
};

export default TaskID2;
