import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './views/dashboard/Dashboard';
import Login from './views/pages/login/Login';
import Page404 from './views/pages/page404/Page404';
import Poli from './views/pages/poli/Poli';
import TaskID from './views/pages/taskID/TaskID';
import TaskID2 from './views/pages/taskID/TaskID2';

// container
const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'));

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' name='Login' element={<Login />} />
        <Route path='*' name='Login' element={<Page404 />} />
        <Route
          path='/'
          name='Dashboard'
          element={
            <DefaultLayout>
              <Dashboard />
            </DefaultLayout>
          }
        />
        <Route
          path='/task-id'
          name='TaskID'
          element={
            <DefaultLayout>
              <TaskID />
            </DefaultLayout>
          }
        />
        <Route
          path='/task-id/:kdbooking'
          name='TaskIDKodeBoking'
          element={
            <DefaultLayout>
              <TaskID2 />
            </DefaultLayout>
          }
        />
        <Route
          path='/pasien'
          name='Pasien'
          element={
            <DefaultLayout>
              <Poli />
            </DefaultLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
