import React from 'react';

const CardAnimation = () => {
	return (
		<div className='border border-gray-300 shadow-md rounded-md w-full mx-auto'>
			<div className='animate-pulse'> 
				<div className='py-2 px-4 bg-gray-300 mb-2 h-10'></div>
				<div className='flex-1 space-y-6 py-4 px-4'>
					<div className='space-y-3'>
						<div className='grid grid-cols-3 gap-4'>
							<div className='h-2 bg-gray-200 rounded col-span-2'></div>
							<div className='h-2 bg-gray-200 rounded col-span-1'></div>
							<div className='h-2 bg-gray-200 rounded col-span-2'></div>
							<div className='h-2 bg-gray-200 rounded col-span-1'></div>
							<div className='h-2 bg-gray-200 rounded col-span-2'></div>
							<div className='h-2 bg-gray-200 rounded col-span-1'></div>
						</div>
					</div>
					<div className='h-2 bg-gray-200 rounded'></div>
				</div>
			</div>
		</div>
	);
};

export default CardAnimation;
