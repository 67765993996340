import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from '../../../utils/Contants';
import { BiErrorCircle } from 'react-icons/bi';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/image/RSUMPO.png';

const Login = () => {
	let navigate = useNavigate();
	const [progress, setProgress] = useState(false);
	const [error, setError] = useState(false);
	const [input, setInput] = useState({
		username: '',
		password: '',
	});

	// handle Input
	const handleInput = (e) => {
		let { name, value } = e.target;
		setInput({ ...input, [name]: value });
	};

	const handleSubmitLogin = async (e) => {
		e.preventDefault();
		setProgress(true);
		let { username, password } = input;
		try {
			let { data } = await axios.post(`${API_URL}auth/login`, {
				username,
				password,
			});
			Cookies.set('token', data.token);
			Cookies.set('tanggal', new Date().toISOString().split('T')[0]);
			navigate('/');
		} catch (err) {
			let { data } = err.response;
			setError(data.message);
		}
		setProgress(false);
	};

	const toastError = (
		<div
			id='toast-danger'
			className='flex items-center p-4 mb-4 w-full text-gray-500 bg-red-50 '
			role='alert'
		>
			<div className='inline-flex flex-shrink-0 justify-center items-center text-red-500 dark:text-red-200'>
				<BiErrorCircle className='text-xl' />
				<span className='sr-only'>Error icon</span>
			</div>
			<div className='ml-3 text-sm font-normal'>{error}</div>
			<button
				type='button'
				className='ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8'
				data-dismiss-target='#toast-danger'
				aria-label='Close'
				onClick={() => setError(false)}
			>
				<span className='sr-only'>Close</span>
				<svg
					aria-hidden='true'
					className='w-5 h-5'
					fill='currentColor'
					viewBox='0 0 20 20'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fillRule='evenodd'
						d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
						clipRule='evenodd'
					></path>
				</svg>
			</button>
		</div>
	);

	const buttonLoading = (
		<button
			disabled
			type='button'
			className='uppercase focus:outline-none text-white bg-[#2B7A0B] hover:bg-[#5BB318] focus:ring-4 focus:ring-[#EAE509] font-medium rounded-lg text-sm px-5 py-2.5 h-[45]'
		>
			<svg
				aria-hidden='true'
				role='status'
				className='inline mr-3 w-4 h-4 text-white animate-spin'
				viewBox='0 0 100 101'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
					fill='#E5E7EB'
				/>
				<path
					d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
					fill='currentColor'
				/>
			</svg>
			Loading ...
		</button>
	);

	const buttonSubmit = (
		<button
			type='submit'
			className='focus:outline-none text-white bg-[#2B7A0B] hover:bg-[#5BB318] focus:ring-4 focus:ring-[#EAE509] font-medium rounded-lg text-sm uppercase px-5 py-2.5 h-[45]'
		>
			Sign In
		</button>
	);

	const LoginForm = (
		<div>
			<div className='flex h-screen items-center justify-center md:p-0 p-4'>
				<div className='sm:w-[500px] w-full'>
					{error && toastError}
					<div className='p-8 border border-gray-100 shadow-2xl'>
						<h3 className='text-center mb-6 text-3xl font-bold flex items-center'>
							<img src={logo} alt='img' className='mr-2 w-14 h-14' />
							LOGIN
						</h3>
						<form className='flex flex-col gap-4' onSubmit={handleSubmitLogin}>
							<div>
								<div className='mb-2 block'>
									<label
										htmlFor='username'
										className='block text-sm font-medium text-gray-900 dark:text-gray-300 leading-none'
									>
										Username
									</label>
								</div>
								<input
									onChange={handleInput}
									value={input.username}
									name='username'
									id='username'
									type='text'
									autoComplete='off'
									className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#2B7A0B] focus:border-[#2B7A0B] block w-full px-5 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mr-2'
									required
								/>
							</div>
							<div>
								<div className='mb-2 block'>
									<label
										htmlFor='password'
										className='block text-sm font-medium text-gray-900 dark:text-gray-300 leading-none'
									>
										Password
									</label>
								</div>
								<input
									onChange={handleInput}
									value={input.password}
									name='password'
									type='password'
									id='password'
									autoComplete='off'
									className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#2B7A0B] focus:border-[#2B7A0B] block w-full px-5 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mr-2'
									required
								/>
							</div>
							<div className='flex justify-between items-center mb-2'>
								<div className='form-group form-check'>
									<input
										type='checkbox'
										className='form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
										id='exampleCheck2'
									/>
									<label
										className='form-check-label inline-block text-gray-800 text-sm'
										htmlFor='exampleCheck2'
									>
										Remember me
									</label>
								</div>
							</div>
							{progress ? buttonLoading : buttonSubmit}
						</form>
					</div>
				</div>
			</div>
		</div>
	);

	if (Cookies.get('token') !== undefined) {
		return <Navigate to={'/'} />;
	} else if (Cookies.get('token') === undefined) {
		return LoginForm;
	}
};

export default Login;
