import React, { useEffect, useState } from 'react';
import { API_URL } from '../../utils/Contants';
import Card from '../pages/base/cards/Card';
import axios from 'axios';
import CardAnimation from '../pages/base/cards/CardAnimation';
import { BsFillInboxFill } from 'react-icons/bs';
import Cookies from 'js-cookie';

const Dashboard = () => {
	const handleInput = (e) => {
		let { name, value } = e.target;
		setInputs({ ...inputs, [name]: value });
	};

	const [inputs, setInputs] = useState({
		tanggal: Cookies.get('tanggal'),
		dataPasien: [],
		loading: false,
		dataDummy: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
	});

	useEffect(() => {
		setInputs((i) => ({ ...i, loading: (i.loading = true) }));
		axios
			.post(`${API_URL}dashboard`, {
				tanggal: inputs.tanggal,
			})
			.then((resp) => {
				Cookies.set('tanggal', inputs.tanggal);
				let result = resp.data;
				if (result.code === 0 || result.code === 201) {
					setInputs((i) => ({
						...i,
						loading: (i.loading = false),
						dataPasien: [],
					}));
				} else {
					setInputs((i) => ({
						...i,
						dataPasien: (i.dataPasien = [...result.original.response.list]),
					}));
					setInputs((i) => ({ ...i, loading: (i.loading = false) }));
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, [inputs.tanggal]);

	return (
		<div className='relative'>
			<div className='flex flex-row mx-auto mb-3 md:w-1/6 w-full'>
				<input
					id='first_name'
					required
					onChange={handleInput}
					value={inputs.tanggal}
					name='tanggal'
					type='date'
					className='mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-green-500 focus:ring-0 focus:ring-green-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
      invalid:border-pink-500 invalid:text-pink-600
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
				/>
			</div>
			{inputs.loading ? (
				<div className='grid grid-cols-1 md:grid-cols-3 gap-4 absolute w-full'>
					{inputs.dataDummy.map((res, idx) => (
						<CardAnimation key={idx} />
					))}
				</div>
			) : (
				<div>
					{inputs.dataPasien.length === 0 ? (
						<h2 className='text-center font-normal text-xl mt-20 text-green-400'>
							<div className='flex items-center justify-center'>
								<BsFillInboxFill className='text-6xl drop-shadow-lg' />
							</div>
							No Data
						</h2>
					) : (
						<div className='grid grid-cols-1 2xl:grid-cols-4 md:grid-cols-3 gap-4 mb-10'>
							{inputs.dataPasien.map((res, index) => {
								return <Card result={res} tgl={inputs.tanggal} key={index} />;
							})}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Dashboard;
